import React from "react";
import { useColorMode } from "theme-ui";

export default function Logo() {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;

  if (typeof window !== "undefined" && window.localStorage) {
    const selectedMode = window.localStorage.getItem("theme-ui-color-mode");
    if (!selectedMode) {
      setColorMode("dark");
    }
  }

  return (
    <svg
      height="35px"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 610.000000 468.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,468.000000) scale(0.100000,-0.100000)"
        fill={isDark ? "#9e0e17" : "#CF1F2C"}
        stroke="none"
      >
        <path
          d="M1556 4548 c-32 -73 -75 -171 -96 -218 -32 -72 -107 -245 -192 -440
-50 -115 -79 -182 -116 -265 -22 -49 -57 -128 -77 -175 -20 -47 -43 -99 -51
-117 -19 -42 -118 -268 -159 -363 -18 -41 -37 -86 -43 -100 -6 -14 -54 -124
-107 -245 -53 -121 -112 -256 -131 -300 -20 -44 -46 -105 -59 -135 -13 -30
-42 -95 -63 -145 -46 -103 -65 -146 -116 -265 -20 -47 -50 -114 -66 -150 -34
-78 -90 -205 -155 -355 -26 -60 -65 -148 -86 -194 -46 -98 -47 -120 -15 -192
13 -30 56 -130 95 -224 39 -93 82 -195 96 -225 29 -66 76 -175 140 -327 33
-78 53 -113 64 -113 9 0 34 11 56 23 118 68 524 303 550 317 17 10 57 33 90
52 54 32 150 87 237 137 18 10 62 36 98 56 36 21 110 64 165 95 55 31 127 73
160 92 319 185 431 249 557 320 81 46 148 88 148 94 0 5 -11 34 -25 62 -14 29
-25 56 -25 60 0 5 -12 33 -26 63 -15 30 -32 70 -40 89 -7 19 -29 73 -49 120
-20 47 -55 129 -78 183 -22 53 -46 97 -52 97 -6 0 -31 -13 -56 -28 -24 -15
-71 -43 -104 -61 -67 -38 -90 -51 -185 -106 -36 -21 -110 -64 -165 -95 -55
-31 -127 -73 -160 -92 -81 -47 -389 -225 -444 -256 -25 -13 -70 -40 -100 -58
-31 -18 -57 -31 -58 -30 -3 3 34 101 63 164 13 29 24 55 24 58 0 3 9 24 19 47
11 24 40 92 66 152 25 61 57 135 70 165 13 30 44 102 68 160 120 287 193 457
242 570 14 30 44 102 68 160 114 271 181 431 242 570 43 98 91 212 150 353 31
75 60 137 64 137 4 0 19 -30 35 -67 16 -38 43 -102 61 -143 18 -41 63 -147
100 -235 37 -88 78 -184 91 -213 13 -29 24 -55 24 -58 0 -3 9 -24 19 -47 11
-24 40 -92 66 -152 25 -61 57 -135 70 -165 13 -30 44 -102 68 -160 120 -287
193 -457 242 -570 14 -30 44 -102 68 -160 119 -283 190 -452 242 -570 13 -30
58 -136 100 -235 81 -193 95 -226 148 -350 19 -44 53 -125 77 -180 23 -55 51
-119 61 -142 11 -23 19 -44 19 -47 0 -3 13 -34 29 -68 15 -35 31 -73 35 -85
l7 -23 1275 0 1274 0 0 365 0 365 -995 0 c-730 0 -995 3 -995 11 0 6 -15 46
-34 88 -89 200 -199 452 -252 574 -70 163 -83 193 -118 270 -20 43 -36 80 -36
82 0 2 -11 28 -24 57 -13 29 -41 91 -61 138 -20 47 -49 114 -65 150 -50 114
-106 241 -160 365 -28 66 -68 156 -87 200 -78 177 -103 233 -133 305 -18 41
-40 93 -50 115 -20 45 -73 165 -132 300 -21 50 -50 115 -63 145 -13 30 -42 96
-63 145 -35 78 -84 192 -177 405 -13 30 -44 100 -68 155 -24 55 -58 134 -76
175 -18 41 -52 118 -75 170 l-42 95 -390 3 -391 2 -57 -132z"
        />
        <path
          d="M2970 4670 c0 -6 14 -41 31 -78 17 -37 51 -114 76 -172 25 -58 53
-123 63 -145 9 -22 34 -78 54 -125 20 -47 49 -111 63 -142 l27 -58 1408 0
1408 0 0 365 0 365 -1565 0 c-1059 0 -1565 -3 -1565 -10z"
        />
        <path
          d="M3835 2688 c6 -21 37 -92 115 -268 16 -36 46 -103 66 -150 57 -132
60 -140 98 -222 l35 -78 975 0 976 0 0 370 0 370 -1136 0 -1135 0 6 -22z"
        />
      </g>
    </svg>
  );
}
